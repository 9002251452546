<template>
  <b-container class="summary">
    <div class="px-3">
      <b-row align-v="center" class="mt-2">
        <b-col cols="1" class="d-sm-none">
          <button-back
            :link="{ name: 'report', query: { from: this.from, to: this.to } }"
          ></button-back>
        </b-col>
        <b-col cols="11" class="d-lg-none text-center">
          <h4 class="mr-auto">Tổng kết lời lỗ</h4>
        </b-col>
      </b-row>
      <b-row align-v="center" class="mb-2">
        <b-col sm="6" cols="12" class="mb-2_ text-right">
          <date-range
            controlContainerClas="form-control form-control-sm"
            :from="from"
            :to="to"
            @date-change="dateChange"
          ></date-range>
        </b-col>
      </b-row>
    </div>
    <div class="content px-3">
      <b-table-simple striped hover>
        <b-thead>
          <b-tr>
            <b-td class="stt"><b></b></b-td>
            <b-th class="text-left">Khách</b-th>
            <b-th class="text-right">Bắc</b-th>
            <b-th class="text-right">Trung</b-th>
            <b-th class="text-right">Nam</b-th>
            <b-th class="text-right">Tổng</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr class="sum">
            <b-td></b-td>
            <b-td class="name"><b>TẤT CẢ</b></b-td>
            <b-td class="text-right" v-html="formatWinloss(total.mb)"></b-td>
            <b-td class="text-right" v-html="formatWinloss(total.mt)"></b-td>
            <b-td class="text-right" v-html="formatWinloss(total.mn)"></b-td>
            <b-td class="text-right" v-html="formatWinloss(total.total)"></b-td>
          </b-tr>
          <template v-for="(item, itemIndex) in tableData">
            <b-tr class="row-content-1" :key="'1_' + itemIndex">
              <b-td class="stt">{{ itemIndex + 1 }}</b-td>
              <b-td class="name">{{ item.player }}</b-td>
              <b-td class="text-right" v-html="formatWinloss(item.mb)"></b-td>
              <b-td class="text-right" v-html="formatWinloss(item.mt)"></b-td>
              <b-td class="text-right" v-html="formatWinloss(item.mn)"></b-td>
              <b-td
                class="text-right"
                v-html="formatWinloss(item.total)"
              ></b-td>
            </b-tr>
            <b-tr
              class="row-content-2"
              :key="'2_' + itemIndex"
              v-if="item.percent != 0"
            >
              <b-td
                class="text-right"
                colspan="6"
                v-html="
                  'Theo ' +
                    item.percent +
                    '%: ' +
                    formatWinloss((item.total * item.percent) / 100.0)
                "
              ></b-td>
            </b-tr>
          </template>
          <b-tr class="sum" v-if="tableData.length > 20">
            <b-td class="name"></b-td>
            <b-td class="text-right" v-html="formatWinloss(total.mb)"></b-td>
            <b-td class="text-right" v-html="formatWinloss(total.mt)"></b-td>
            <b-td class="text-right" v-html="formatWinloss(total.mn)"></b-td>
            <b-td class="text-right" v-html="formatWinloss(total.total)"></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <infinite-loading :identifier="infiniteId" @infinite="fetchAmountReport">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </b-container>
</template>
<script>
import moment from "moment";
import { Utilities } from "@/utilities";
import InfiniteLoading from "vue-infinite-loading";
// import predefinedRangesPlugin from "@/components/flatpicker/plugin/presetRange.js";
// import "@/components/flatpicker/plugin/presetRange.css";
import DateRange from "@/components/DateRange";

export default {
  name: "Report",
  components: {
    InfiniteLoading,
    DateRange
  },
  data() {
    return {
      total: { mb: 0, mt: 0, mn: 0, total: 0 },
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      tableData: [],
      infiniteId: +new Date(),
      page: 1
    };
  },
  watch: {
    // date: async function(newDate) {
    // let r = {
    //   name: this.$router.history.current.name,
    //   query: { ...this.$router.history.current.query, date: newDate }
    // };
    // await this.$nextTick();
    // if (this.$router.history.current.query.date == this.date) return;
    // this.resetPage();
    // this.$router.replace(r);
    // // this.fetchAmountReport();
    // }
  },
  created() {
    this.bindDate();
    // this.fetchAmountReport();
  },
  methods: {
    async fetchAmountReport($state) {
      let res = await this.$http.get("/report/winloss", {
        params: { from: this.from, to: this.to, page: this.page }
      });

      if (res.data.code == 0) {
        if (res.data.data.detail.length == 0) {
          $state.complete();
          return;
        }

        this.tableData = [...this.tableData, ...res.data.data.detail];
        if (res.data.data.total != null) this.total = res.data.data.total;
        this.page++;

        if (res.data.data.detail.length < 20) {
          $state.complete();
        } else {
          $state.loaded();
        }
      }
    },
    resetPage() {
      this.page = 1;
      this.tableData = [];
      this.total = { mb: 0, mt: 0, mn: 0, total: 0 };
      this.infiniteId++;
    },
    bindDate() {
      this.from = this.$route.query.from
        ? moment(this.$route.query.from, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");

      this.to = this.$route.query.to
        ? moment(this.$route.query.to, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    },
    formatWinloss(money) {
      let cls = money < 0 ? "text-danger" : "text-primary";
      let m =
        "<span class='" +
        cls +
        "'>" +
        Utilities.currencyReport(money) +
        "</span>";
      return m;
    },
    async dateChange(date) {
      this.from = date.from;
      this.to = date.to;

      await this.$nextTick();

      if (
        this.$router.history.current.query.from == this.from &&
        this.$router.history.current.query.to == this.to
      )
        return;

      this.resetPage();
      this.$router.replace({
        name: this.$router.history.current.name,
        query: {
          ...this.$router.history.current.query,
          from: this.from,
          to: this.to
        }
      });
    }
  }
};
</script>
<style>
.summary table {
  font-size: 0.8rem;
}
.summary .name {
  white-space: pre-wrap !important;
  text-align: left;
}
.sum span {
  font-weight: bold;
}
.row-content-1 td {
  border-bottom: none;
}
.row-content-2 td {
  border-top: none;
}
</style>
